import { Carousel } from './init-carousel';
import { FreeMode, Navigation } from 'swiper/modules';

const carouselOptions = {
    modules: [FreeMode, Navigation],
    navigation: {
        nextEl: '.card-carousel .card-carousel__next',
        prevEl: '.card-carousel .card-carousel__prev',
    },
    freeMode: {
        enabled: true,
        sticky: false,
    },
    slidesPerView: 1.25,
    spaceBetween: 15,
    breakpoints: {
        500: {
            slidesPerView: 2,
        },
        999: {
            slidesPerView: 2.8,
        },
    },
};

const carousel = document.querySelector('[data-carousel="cards"]');

const cardCarousel = new Carousel(carousel, carouselOptions);

cardCarousel.initSwiper();
